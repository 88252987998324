function setupSpecialDays(specialDays=[]) {
	return specialDays.map((day) => {
		if (day["dt"] !== undefined) {
			let date = new Date(day["dt"]);
			return {
				m: date.getMonth(),
				d: date.getDate(),
				typeId: day.typeId,
			}
		}

		return { ...day, m: day.m - 1 };
	});
}

function setupHolidays(holidays=[]) {
	return holidays.map(dayIndex => {
		return dayIndex == 7 ? 0 : dayIndex
	});
}

class CalendarWorkDays {
	holidays = []
	special = []

	constructor(holidaysData, specialData) {
		this.holidays = setupHolidays(holidaysData)
		this.special = setupSpecialDays(specialData)
	}

	isHoliday(date){
		const dateValue = typeof date == 'number' ? 
			date : date.getDay()
		return this.holidays.includes(dateValue)
	}

	isSpecialDate(date){
		if (typeof date == 'number')
			return this.special.includes(date)
		let day = date.getDate()
		let month = date.getMonth()
		return !!this.special
		.find(specialDate => {
			return specialDate.d == day 
				&& specialDate.m == month
		})
	}
}

export default CalendarWorkDays