import { isEqualDate } from "./date"

class CalendarWorkTime {
	
	isAllowPastTime = false
	
	/**
	 * Calendar work time instance
	 * @param {Date} start - calendar work start
	 * @param {Date} end - calendar work end
	 */
	constructor (start, end, isAllowPastTime = false) {
		this.start = start
		this.end = end
		this.start.setSeconds(0)
		this.end.setSeconds(0)
		this.isAllowPastTime = isAllowPastTime
	}
	
	/**
	 * Make the projection on the date and return new instance of CalendarWorkTime
	 * @param {Date} date - projection date
	 * @returns new instance
	 */
	projection(date) {

		const diff = isEqualDate(date, this.start)?
			0 : date.getTime() - this.start.getTime()
		const diffDays = Math.round(diff / (1000 * 3600 * 24))

		let projectionStart = new Date(this.start)
		projectionStart.setDate(this.start.getDate() + diffDays)
		
		let projectionEnd = new Date(this.end)
		projectionEnd.setDate(this.end.getDate() + diffDays)
		return new CalendarWorkTime(projectionStart, projectionEnd, this.isAllowPastTime)
	}

	/**
	 * Make the list of hours between start and end of work
	 */
	range() {
		let result = []
		const startHour = this.start.getHours()
		const endHour = this.end.getHours()

		if (startHour == 0 && endHour == 0 && !isEqualDate(this.start, this.end)){
			for (let hour = startHour; hour < 24; hour++) {
				result.push(hour)
			}
			return (result)
		}

		for (let hour = startHour; hour < endHour; hour++) {
			result.push(hour)
		}

		return result
	}
	
	startTime(){
		return {
			hour: this.start.getHours(),
			minute: this.start.getMinutes(),
		}
	}

	endTime(){
		return {
			hour: this.end.getHours(),
			minute: this.end.getMinutes(),
		}
	}

	includes(date){
		
		let time
		let startDate = new Date(date)
		time = this.startTime()
		startDate.setHours(time.hour, time.minute)
		let endDate = new Date(date)
		time = this.endDate()
		endDate.setDate(time.hour, time.minute)
		return date >= startDate && date <= endDate
	}
}

export default CalendarWorkTime