class AttenderConfirmation {
	fullName = ""
	userId = 0
	isConfirm = false
	price = null
	comment = null
	constructor(params) {
		this.userId = params.userId
		this.fullName = params.fullName
		this.isConfirm = params.isConfirm ?? false
		this.price = params.price ?? null
		this.comment = params.comment ?? null
	}
}

class AttenderMember {
	firstName;
	lastName;
	fullName;
	userId;
	constructor(params) {
		this.firstName = params.firstName;
		this.lastName = params.lastName;
		this.userId = params.userId;
		this.fullName = makeName(this.firstName, this.lastName)
	}
}	

function makeName(first, second) {
	let result = first
	if (second && second.length > 0)
		result += " " + second
	return result
}

class Attender {
	firstName;
	lastName;
	fullName;
	userId;
	isRegistered;
	isDisabled;

	dependents=[];
	householdId=null
	constructor(params, isDisabled=false) {
		this.firstName = params.firstName;
		this.lastName = params.lastName;
		this.isRegistered = params.isRegistered;
		this.userId = params.userId;
		this.fullName = makeName(this.firstName, this.lastName)
		this.isDisabled = isDisabled;
		if (params.householdId) {
			this.householdId = params.householdId;
		}
		if (params.dependents) {
			this.dependents = params.dependents.map(d => new AttenderMember(d));
		}
	}

	name(){
		return this.name;
	}
}

export {
	AttenderConfirmation
}

export default Attender