<template>
	<div class="restricted_table">
		<div class="restricted_message">
			<div class="restricted-message__image">
                <span class="iconify" data-icon="emojione:framed-picture"></span>
			</div>
			<div class="restricted-message__title">
				Oops! Access Denied
			</div>
			<div class="restricted-message__message" v-if="calendar">
				We're sorry, but this calendar is 
				<span class="restricted-access">{{ accessTitle() }}</span>.
				<span 
				v-if="[3,4].includes(calendar.visibilityLevelId) 
						&& calendar.suggestedAction">
					To see the full information please {{ calendar.suggestedAction }}.
				</span>
			</div>
		</div>
		<b-button type="is-info" expanded
		v-if="suggestedActionTitle()"
		@click="suggestedActionHandler">
			{{ suggestedActionTitle() }}
		</b-button>
		<b-button v-else type="is-info" expanded @click="toCalendarList">
			To Calendar List
		</b-button>
	</div>
</template>

<script>
import Calendar from '../../scripts/calendar';

export default {
	name: 'RestrictedMessage',
	computed: {
		calendar(){
			return this.$store.getters.currentCalendar()
		},
	},
	methods: {
		accessTitle(){
			if (!this.calendar)
				return ""
			let visibilityLevelId = this.calendar.visibilityLevelId
			if (visibilityLevelId == 1)
				return "private"
			if (visibilityLevelId == 3)
				return "for authorized users only"
			if (visibilityLevelId == 4)
				return "for followed users only"
			return ""
		},
		suggestedActionTitle(){
			if (!this.calendar)
				return null
			let action = this.calendar.suggestedAction
			if (!action || action == "")
				return null
			return action[0].toUpperCase() + action.slice(1);
		},
		suggestedActionHandler(){
			if (!this.calendar)
				return null
			let action = this.calendar.suggestedAction
			if (!action || action == "")
				return null
			if (action == "follow") {
				// TODO : make redirect to the follow execution
			} else if (action == "authorize") {
				localStorage.setItem("originPage-auth", JSON.stringify({
					name: this.$route.name,
					params: this.$route.params,
					query: this.$route.query
				}))
				this.$router.push({name: "auth"})
			}
		},
		toCalendarList(){
			this.$router.replace({name: "calendar-list"})
		},
	},
};
</script>

<style scoped>
.iconify{
	height: 105px;
    width: 170px;
}

.restricted_table{
	height: calc(100% - 32px);
	padding: 16px;
	display: flex;
	flex-direction: column;
}

.restricted_message{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;
	gap: 5px;
}
.restricted-message__title{
	font-size: 18px;
	font-weight: 600;
}

.restricted-message__message{
	font-size: 14px;
	text-align: center;
}

.restricted-access{
	text-decoration: underline;
}
</style>